export interface IUserSettings {
  enableCalendarInvites: boolean | null;
  enableCheckInReminderPN: boolean | null;
  enableCheckInReminderEmail: boolean | null;
  enableAutoCancellationNoticeEmail: boolean | null;
  enableAutoCancellationNoticePN: boolean | null;
  enableScheduleReminderEmail: boolean | null;
  enableScheduleReminderPN: boolean | null;
  enableFollowRequestNoticeEmail: boolean | null;
  enableFollowRequestNoticePN: boolean | null;
  enableHrisStatusSyncEmail: boolean | null;
  app?: {
    anonymousBookings?: boolean;
    colleaguesStatusSendUpdatesToMe?: boolean;
    colleaguesStatusSendUpdatesToUser?: boolean;
    enableTracking?: {
      necessary?: boolean;
      googleAnalytics?: boolean;
      mixpanel?: boolean;
    };
  };
}
