import { Environment, firebaseSandboxLabRats } from "common-module";

// Used to dynamically set the sentry sample rate for routes beginning with the key
const sentryDynamicSampleRate: { [key: string]: number } = {};

export const environment = {
  env: Environment.SANDBOX_LAB_RATS,
  firebase: firebaseSandboxLabRats,
  apiURL: "https://api.lab-rats.deskbird.com",
  appURL: "https://lab-rats.deskbird.com",
  publicApiUrl: "https://connect.lab-rats.deskbird.com",
  backendSlackLoginUrl:
    "https://europe-west3-deskbird-sandbox-labrats.cloudfunctions.net/slack/internal/link",
  enableServiceWorker: false,

  // Sentry configuration
  sentrySampleRate: 0,
  sentryRelease:
    "deskbird-webapp-staging@e15b76776f6494dc8d40aa50f7d400f977d56a67",
  sentryDynamicSampleRate,
  // End Sentry configuration

  // Google Tag Manager
  gtmId: "GTM-MG6ZS9M",
  gtmAuth: "PRVGDohRlSYSa0l2nZdeCA",
  gtmPreview: "env-30",
  // End Google Tag Manager

  // Customer.io configuration
  customerIoSiteId: "99e495fe1b9f8d3212a8", //  deskbird [Labrats]
  customerIoUseInAppMessages: true,
  // End Customer.io configuration
};
