<db-multiselect
  [ngClass]="class"
  [options]="$any(group ? groupedOptions : singleItemOptions)"
  [optionLabel]="'label'"
  [optionValue]="'value'"
  [chip]="true"
  [group]="group"
  [showToggleAll]="showToggleAll"
  [showClear]="showClear"
  [filter]="true"
  [filterBy]="'label,userEntity.email'"
  [panelStyleClass]="panelStyleClass"
  [placeholder]="placeholder"
  [dataTestId]="dataTestId"
  [resetFilterOnHide]="true"
  [itemTemplate]="itemTemplate"
  [groupTemplate]="groupTemplate"
  [virtualScroll]="virtualScroll"
  (onChange)="selectedValuesHandler($event.value)"
  (onClear)="clearSelectionHandler()"
/>

<ng-template #itemTemplate let-item>
  <div [ngSwitch]="item.type">
    <db-user-info
      *ngSwitchCase="UserMultiselectType.USER"
      [userInfo]="item.userEntity"
      [showUserEmail]="true"
      avatarSize="mini"
      [ignoreEmailMaxWidth]="false"
    >
    </db-user-info>

    <div *ngSwitchDefault>
      {{ item.label }}
    </div>
  </div>
</ng-template>

<ng-template #groupTemplate let-group>
  <div class="flex align-items-center">
    <span>{{ group.label }}</span>
  </div>
</ng-template>
