import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IUserMultiselectEntity } from 'shared-types';
import { UserMultiselectType } from 'shared-enums';
import { MultiselectComponent } from '../multiselect/multiselect.component';
import { UserInfoComponent } from '../user-info';
import { SelectItemGroup } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'db-multiselect-users-and-or-groups',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    MultiselectComponent,
    UserInfoComponent
  ],
  templateUrl: './multiselect-users-and-or-groups.component.html',
  styleUrls: ['./multiselect-users-and-or-groups.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiselectUsersAndOrGroupsComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/** Multiselect component that supports multiple scenarios -
 * selection of users, selection of users and user groups, selection of users and user groups and other custom options (e.g. all users in the office) */
export class MultiselectUsersAndOrGroupsComponent implements OnChanges {
  cd = inject(ChangeDetectorRef);

  @Input({required : true}) options!: IUserMultiselectEntity[];
  @Input() dataTestId?: string;
  @Input() placeholder: string = '';
  @Input() class: string = 'w-100'; // Style class of the overlay panel element.
  @Input() panelStyleClass: string = 'multiselect-panel-width-550';
  @Input() group: boolean = false;
  @Input() showToggleAll: boolean = true;
  @Input() showClear: boolean = true;
  @Input() virtualScroll: boolean = false; // Whether the data should be loaded on demand during scroll.
  @Input() virtualScrollItemSize: number = 48; // Height of an item in the list for VirtualScrolling.

  @Output() selectedValueChanged = new EventEmitter<string>();

  @ViewChild(MultiselectComponent, { read: MultiselectComponent, static: true })
  dbMultiselect!: MultiselectComponent<IUserMultiselectEntity>;
  readonly UserMultiselectType = UserMultiselectType;

  singleItemOptions: IUserMultiselectEntity[] = [];
  groupedOptions: SelectItemGroup[] = [];

  selectedItems: IUserMultiselectEntity[] = [];
  selectedValue: string | null = null; // values of selected items concatenated with comma
  maxVisibleSelectedOptions = 1;

  writeValue(obj: unknown): void {
    this.selectedValue = typeof obj === 'string' ? obj : null;
    const selectedValues = typeof obj === 'string' ? obj.split(',') : [];
    this.selectedItems = (this.options ?? []).filter((option) => selectedValues.includes(option.value));
    this.dbMultiselect.writeValue(selectedValues);
    this.cd.detectChanges();
  }

  registerOnChange(fn: (_:unknown) => void): void {
    this.dbMultiselect.registerOnChange(fn);
  }

  registerOnTouched(fn: (_:unknown) => void): void {
    this.dbMultiselect.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.dbMultiselect.setDisabledState(isDisabled);
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes['options']?.currentValue || changes['group']?.currentValue)) {
      const inputOptions = (changes['options'].currentValue as IUserMultiselectEntity[]) || this.options;
      const inputGroup = (changes['group']?.currentValue as boolean) || this.group;

      const userOptions = inputOptions.filter((option) => option.type === UserMultiselectType.USER);
      const groupOptions = inputOptions.filter((option) => option.type === UserMultiselectType.USER_GROUP);

      if (inputGroup) {
        this.groupedOptions = [];
        if (userOptions.length) {
          this.groupedOptions.push({ label: 'Users', value: UserMultiselectType.USER, items: userOptions });
        }
        if (groupOptions.length) {
          this.groupedOptions.push({ label: 'User groups', value: UserMultiselectType.USER_GROUP, items: groupOptions });
        }
      }
      else{
        this.singleItemOptions = [...userOptions, ...groupOptions];
      }
    }
  }

  getOptionValues = (selectedItems: IUserMultiselectEntity[]): string[] => {
    return selectedItems.map((item) => item.value);
  };

  selectedValuesHandler = (selectedValues: string[]): void => {
    this.selectedItems = this.options.filter((option) => selectedValues.includes(option.value));
    this.selectedValue = selectedValues.join(',');
    this.selectedValueChanged.emit(this.selectedValue);
  };

  clearSelectionHandler = (): void => {
    this.selectedValuesHandler([]);
  };
}
