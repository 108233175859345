<!-- WEB APP -->

<db-menu-item
  [link]="[clientPartPrefix, 'default']"
  [style]="'nav-with-border-admin'"
  imgSrc="/assets/icons/arrow-back.svg"
  title="Web app"
  [title]="menuItemLabels['web-app']"
>
</db-menu-item>

<br />

<!-- OFFICE CONFIGURATION -->
<db-menu-item-with-children
  *ngIf="
    (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
    ([adminPartPrefix, 'company'] | exec : hasAccessToPage)
  "
  [link]="[
    adminPartPrefix,
    'company',
    companyId,
    officeId ? 'office' : 'office-redirect',
    officeId ? officeId : 'default',
    'dashboard'
  ]"
  [hideIcons]="hideIcons"
  [linkActiveOptions]="{ exact: true }"
  queryParamsHandling="merge"
  [style]="'admin'"
  imgSrc="/assets/icons/configuration.svg"
  [title]="menuItemLabels['office-settings']"
>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      [
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'dashboard',
        'essentials'
      ] | exec : hasAccessToPage
    "
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'dashboard',
      'essentials'
    ]"
    [title]="menuItemLabels['general-configuration']"
  >
  </db-menu-item>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      [
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'dashboard',
        'booking-settings'
      ] | exec : hasAccessToPage
    "
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'dashboard',
      'booking-settings'
    ]"
    [title]="menuItemLabels['booking-settings']"
  >
  </db-menu-item>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      [
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'floors-and-spaces'
      ] | exec : hasAccessToPage
    "
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'floors-and-spaces'
    ]"
    [title]="menuItemLabels['floors-spaces']"
  >
  </db-menu-item>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      officeEquipmentEnabled &&
      ([
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'office-equipment'
      ] | exec : hasAccessToPage)
    "
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'office-equipment'
    ]"
    [title]="menuItemLabels['office-equipment']"
  >
  </db-menu-item>
</db-menu-item-with-children>

<!-- Hybrid work POLICIES -->
<db-menu-item-with-children

  [link]="[adminPartPrefix, 'company', companyId, 'hybrid-work-policies']"
  [hideIcons]="hideIcons"
  [linkActiveOptions]="{ exact: false }"
  queryParamsHandling="merge"
  [title]="menuItemLabels['hybrid-work-policies']"
  [style]="'admin'"
  imgSrc="/assets/icons/hybrid-work-policies-purple.svg"
>
</db-menu-item-with-children>

<!-- MEETING ROOM CONFIGURATION -->
<db-menu-item-with-children
  *ngIf="
    (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
    ([adminPartPrefix, 'company'] | exec : hasAccessToPage)
  "
  [hideIcons]="hideIcons"
  [link]="[
    adminPartPrefix,
    'company',
    companyId,
    officeId ? 'office' : 'office-redirect',
    officeId ? officeId : 'default',
    'meeting-room-settings'
  ]"
  [linkActiveOptions]="{ exact: true }"
  queryParamsHandling="merge"
  [style]="'admin'"
  imgSrc="/assets/icons/meeting-room-area-type.svg"
  [title]="menuItemLabels['meeting-room-settings']"
>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      [
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'meeting-room-settings',
        'general-setup'
      ] | exec : hasAccessToPage
    "
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'meeting-room-settings',
      'general-setup'
    ]"
    [title]="menuItemLabels['meeting-room-general-setup']"
  >
  </db-menu-item>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      cateringAndServicesEnabled &&
      ([
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'meeting-room-settings',
        'catering-and-services'
      ] | exec : hasAccessToPage)
    "
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'meeting-room-settings',
      'catering-and-services'
    ]"
    [title]="menuItemLabels['meeting-room-catering-and-services']"
  >
  </db-menu-item>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      kioskModeEnabled &&
      ([
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'meeting-room-settings',
        'kiosk'
      ] | exec : hasAccessToPage)
    "
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'meeting-room-settings',
      'kiosk'
    ]"
    [title]="menuItemLabels['meeting-room-kiosk']"
  >
  </db-menu-item>
</db-menu-item-with-children>

<!-- ANALYTICS -->
<db-menu-item-with-children
  *ngIf="
    [adminPartPrefix, 'company', companyId, 'people-analytics']
      | exec: hasAccessToPage
  "
  [hideIcons]="hideIcons"
  [link]="[adminPartPrefix, 'company', companyId, 'people-analytics', 'people']"
  [linkActiveOptions]="{ exact: true }"
  queryParamsHandling="merge"
  [title]="menuItemLabels['analytics']"
  [style]="'admin'"
  imgSrc="/assets/icons/analytics.svg"
>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      [adminPartPrefix, 'company', companyId, 'people-analytics', 'people']
        | exec: hasAccessToPage
    "
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      'people-analytics',
      'people',
    ]"
    [title]="menuItemLabels['schedule-analytics']"
  >
  </db-menu-item>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
      ([
        adminPartPrefix,
        'company',
        companyId,
        officeId ? 'office' : 'office-redirect',
        officeId ? officeId : 'default',
        'office-analytics',
        'office',
      ] | exec: hasAccessToPage)
    "
    [hideIcons]="hideIcons"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'office-analytics',
      'office',
    ]"
    [title]="menuItemLabels['office-analytics']"
  >
  </db-menu-item>
</db-menu-item-with-children>

<!-- BOOKINGS -->
<db-menu-item-with-children
  *ngIf="
    (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
    ([
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'bookings'
    ] | exec : hasAccessToPage)
  "
  [link]="[
    adminPartPrefix,
    'company',
    companyId,
    officeId ? 'office' : 'office-redirect',
    officeId ? officeId : 'default',
    'bookings'
  ]"
  [hideIcons]="hideIcons"
  [linkActiveOptions]="{ exact: false }"
  queryParamsHandling="merge"
  [title]="menuItemLabels['bookings']"
  [style]="'admin'"
  imgSrc="/assets/icons/bookings.svg"
>
</db-menu-item-with-children>

<!-- EVENTS -->
<db-menu-item-with-children
  *ngIf="
    hasPlanningAccess &&
    (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
    ([
      adminPartPrefix,
      'company',
      companyId,
      officeId ? 'office' : 'office-redirect',
      officeId ? officeId : 'default',
      'office-events'
    ] | exec : hasAccessToPage)
  "
  [hideIcons]="hideIcons"
  [link]="[
    adminPartPrefix,
    'company',
    companyId,
    officeId ? 'office' : 'office-redirect',
    officeId ? officeId : 'default',
    'office-events'
  ]"
  [linkActiveOptions]="{ exact: false }"
  queryParamsHandling="merge"
  [title]="menuItemLabels['office-events']"
  [style]="'admin'"
  imgSrc="/assets/icons/office-events-purple.svg"
>
</db-menu-item-with-children>

<!-- USER SETTINGS -->
<db-menu-item-with-children
  *ngIf="
    (isUserAdmin || isUserDeskbirdAdmin || isUserOfficeAdmin) &&
    ([adminPartPrefix, 'company', companyId, 'user'] | exec : hasAccessToPage)
  "
  [link]="[adminPartPrefix, 'company', companyId, 'user']"
  [linkActiveOptions]="{ exact: true }"
  queryParamsHandling="merge"
  [style]="'admin'"
  [title]="menuItemLabels['user-settings']"
  [hideIcons]="hideIcons"
  imgSrc="/assets/icons/users.svg"
>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      [adminPartPrefix, 'company', companyId, 'user', 'list']
        | exec : hasAccessToPage
    "
    [hideIcons]="hideIcons"
    [link]="[adminPartPrefix, 'company', companyId, 'user', 'list']"
    [title]="menuItemLabels['user']"
  >
  </db-menu-item>

  <db-menu-item
    [style]="'admin'"
    *ngIf="
      [adminPartPrefix, 'company', companyId, 'user', 'user-group']
        | exec : hasAccessToPage
    "
    [hideIcons]="hideIcons"
    [link]="[adminPartPrefix, 'company', companyId, 'user', 'user-group']"
    [title]="menuItemLabels['user-groups']"
  >
  </db-menu-item>
</db-menu-item-with-children>

<!-- INTEGRATIONS -->
<db-menu-item-with-children
  *ngIf="
    (isUserAdmin || isUserDeskbirdAdmin) &&
    ([adminPartPrefix, 'company', companyId, 'integrations']
      | exec : hasAccessToPage)
  "
  [hideIcons]="hideIcons"
  [style]="'integrations'"
  [link]="[adminPartPrefix, 'company', companyId, 'integrations']"
  [title]="menuItemLabels['integrations']"
  imgSrc="/assets/icons/integrations.svg"
>
  <db-menu-item
    [style]="'admin'"
    *ngIf="
      hasIntegrations &&
      ([adminPartPrefix, 'company', companyId, 'integrations', 'list']
        | exec : hasAccessToPage)
    "
    [hideIcons]="hideIcons"
    [link]="[adminPartPrefix, 'company', companyId, 'integrations', 'list']"
    [title]="menuItemLabels['authorized-apps']"
  >
  </db-menu-item>
  <db-menu-item
    [style]="'admin'"
    [hideIcons]="hideIcons"
    [tooltip]="publicApiKeysTooltipLabel()"
    [link]="[
      adminPartPrefix,
      'company',
      companyId,
      'integrations',
      'public-api'
    ]"
    [title]="menuItemLabels['api-keys']"
  >
  </db-menu-item>
</db-menu-item-with-children>

<!-- ADMINISTRATION -->
<db-menu-item-with-children
  *ngIf="isUserDeskbirdAdmin"
  [link]="[adminPartPrefix, 'general', 'settings']"
  [style]="'admin'"
  [title]="menuItemLabels['administration']"
  [hideIcons]="hideIcons"
  imgSrc="/assets/icons/deskbird.svg"
>
</db-menu-item-with-children>

<div class="company-switch-container" *ngIf="isUserDeskbirdAdmin">
  <db-company-switcher></db-company-switcher>
</div>

<div class="footer border-top">
  <div class="support-info">
    <a
      class="cta-help-center small-text"
      href="https://deskbird.zendesk.com/hc/en-us"
      target="_blank"
    >
      <img
        src="assets/icons/help-center-purple.svg"
        alt="help"
        class="help-icon question-icon"
      />
      <div class="text" i18n="@@navigation-module|help-center">Help center</div>
      <img
        src="assets/icons/link-purple.svg"
        alt="link"
        class="help-icon link-icon"
      />
    </a>
  </div>

  <div class="user-info">
    <div class="menu-trigger" (click)="menu.toggle($event)">
      <db-user-info
        [userInfo]="userInfo"
        [avatarSize]="'standard'"
      ></db-user-info>
    </div>
  </div>
  <db-menu #menu [items]="userMenuItems" [popup]="true"></db-menu>
</div>
