import { UserRole, CheckInType } from 'types/enums';
import { IOfficePicture } from './office-picture';
import { IAddress, IOpeningHours } from 'types/interfaces';

export interface IOffice {
  id: string;
  name: string;
  address: IAddress;
  isActive: boolean;

  openingHours: IOpeningHours;

  pictures: Array<IOfficePicture>;

  updatedAt: number;
  businessCompanyId?: string;

  settings: IOfficeBookingSettings;

  userGroupIds: (string | number)[];
  isRestricted: boolean;
}

export interface IOfficeMeetingRoomSettings {
  anonymizeMeetingRoomBookings: boolean;
}

/** How many days ahead (counting from today) the clients can do bookings */
export interface IOfficeLeadTimeSettings {
  value?: number; // default value
  overrides: {
    userRole: UserRole;
    value: number;
  }[];
}

export interface IOfficeBookingSettings extends IOfficeMeetingRoomSettings {
  allowsAnonymousBooking: boolean;
  /** Number of days calculated on the basis of maximumLeadTimeForBookingConfig */
  maximumLeadTimeForBooking: number;
  maximumLeadTimeForBookingConfig: IOfficeLeadTimeSettings;
  checkinGracePeriod: number;
  checkinType: CheckInType;
  minimumTimeForAutomaticCancellation: number;
  guestBookingCreatorRoles: Array<UserRole>;
}

export interface IOfficeBookingSettingsUpdate extends IOfficeMeetingRoomSettings {
  allowAnonymousBookings: boolean;
  maximumLeadTimeForBookingConfig: IOfficeLeadTimeSettings;
  autoCancelGracePeriodInMinutes: number;
  checkInType: CheckInType;
  noAutoCancellationBefore: string;
  guestBookingCreatorRoles: Array<UserRole>;
}
