import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ILoadQrOfficeTemplateResponse,
  IUpdateQrOfficeTemplateResponse,
  IUpdateQrStickerTemplateRequest,
} from './interfaces';
import { Observable } from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root',
})
export class QrGenerationService {
  http = inject(HttpClient);

  loadQrOfficeTemplate(officeId: string): Observable<ILoadQrOfficeTemplateResponse> {
    return this.http.get<ILoadQrOfficeTemplateResponse>(`/api/offices/${officeId}/qrcode/template`);
  }

  updateQrOfficeTemplate(officeId: string, payload: IUpdateQrStickerTemplateRequest): Observable<IUpdateQrOfficeTemplateResponse> {
    let companyImage = payload.companyImage;
    if (this.isValidUrl(companyImage) || companyImage === '') {
      companyImage = undefined;
    }

    if (companyImage) {
      companyImage = companyImage.split(',')[1];
    }

    let fileType = '';
    if (companyImage) {
      companyImage = payload.companyImage!.split(',')[1];
      fileType = payload.companyImage!.split(',')[0].split('image/')[1].split(';')[0];
    }

    return this.http.put<ILoadQrOfficeTemplateResponse>(`/api/offices/${officeId}/qrcode/template`, {
      ...payload,
      companyImage,
      companyImageExt: fileType,
    });
  }

  isValidUrl(url: string | undefined | null): boolean {
    let validUrl = false;
    if (url) {
      const res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

      if (res) {
        validUrl = true;
      }
    }

    return validUrl;
  }
}
